@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300&family=Open+Sans:wght@400;500;600;700;800&display=swap');

html{
    scroll-behavior: smooth;
    font-family: 'Open Sans', sans-serif;
    overflow-x: hidden;
}
body {
    font-family: "Open Sans", sans-serif;
  }

::-webkit-scrollbar {
    display: none;
  }

.BoxShadow{
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

input{
    border-color: rgb(138, 138, 138);
    border-width: 1px;
}

input:focus{
    border-color: #038577;
    border-width: 2px;
    outline:#038577 ;
}

input:focus-visible,
textarea:focus-visible {
  /* border: 2px solid #f05a28; */
  outline-color: #038577;
}

input[type=number] {
    -moz-appearance: textfield;
  }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.App{
  font-family: "Open Sans", sans-serif;
}


.PricingCard:hover .PricingCardSVGDesign  path{
  fill:#E67B25;

}

.PricingCardSVGDesign  path{
  fill:#038577;

}

.IrregularCard:hover .irregularCircle{
  stroke-width: 2px;
  stroke: #E67B25;
  

}

.irregularCircle{
  stroke-width: 2px;
  stroke: #038577;
  /* background-color: red; */
}

.irregularCircle :hover{
  stroke-width: 2px;
  stroke: #E67B25;
  
}

.IrregularCardEgg{
  font-size: 10px;
}

.IrregularCardEgg:hover .irregularCircleEgg{
  stroke-width: 0px;
  stroke: white;
}

.IrregularCardEgg:hover .irregularCircleEgg path{
  fill: rgb(241, 252, 249);
  box-shadow: 120px 80px 40px 20px rgb(241, 252, 249);
}



.irregularCircleEgg:hover{
  stroke-width: 0px;
  stroke: white;
  background-color: white;
  
  
}

.spinner{
  border-radius: 100% ;
  border-color:#038577 #D9D9D9 #D9D9D9 #D9D9D9;
  /* border-width: 5px; */
  animation: spin linear 0.5s ease-in-out; 
  /* animation-iteration-count: infinite;
   animation-direction: normal;   */
}

@keyframes  spin {
  from {
    transform: rotate(0deg);
  }


  to {
      transform: rotate(360deg);
  }  
   
}

.privacyPolicyFont{
  font-family: 'Inconsolata', monospace;
}




.lineClamp{
  display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}

.blocktext{
  display: -webkit-box;
  max-width: 310px;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap:break-word;
  visibility:visible
}